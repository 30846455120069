import { ApexOptions } from 'apexcharts';
import { ValueResponse } from 'entities/dataValue';
import { ChartType } from 'entities/panel';
import { SplitterValue } from 'entities/panel/block/contents/diagram/model/category/splitterValue';
import { defined } from 'shared/lib/checks';
import { getSeriesValue } from './getSeriesValue';

export type BaseData = {
  x: string;
  y: number | null;
  color?: string;
  meta?: {
    groupId: string;
    nodeId?: string;
  };
};

export type BaseSeries = {
  name: string;
  data: BaseData[];
};

export const parseTimeSeries = (
  response: ValueResponse[],
  charts?: SplitterValue[],
  fractions = 0,
  type?: ChartType
): ApexOptions['series'] => {
  const series: Record<string, BaseData[]> = {};
  response.forEach((responseValue) => {
    if (responseValue.byGroups.length === 0) {
      return;
    }

    const getClassifierTitle = (innerId: string) => {
      return responseValue.classifiers?.find((classifier) => classifier.innerId === innerId)?.title ?? '';
    };

    responseValue.byGroups[0]?.byIndicators.forEach((serie) => {
      const classifierId = defined(serie.nodeIds?.[0]).nodeId;
      const name = getClassifierTitle(classifierId);

      const color = charts?.find((chart) => chart.key === serie.key)?.color;

      series[name] = [
        ...(series[name] ?? []),
        {
          color,
          x: responseValue.period?.start ?? new Date().toISOString(),
          y: getSeriesValue(serie, fractions),
        },
      ];
    });
  });

  const result: BaseSeries[] = [];

  for (const key in series) {
    result.push({
      name: key,
      data: defined(series[key]),
    });
  }

  if (type == 'pie' || type == 'donut') {
    return result;
  }

  return result;
};
