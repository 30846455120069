import { ClassifierNode } from 'entities/classifier';
import { ValueRequest, ValueRequestIndicator } from 'entities/dataValue';
import { CategoryDiagramSettings } from 'entities/panel';
import { DateTime } from 'luxon';
import { defined } from 'shared/lib/checks';
import { getDatesFromConfig } from 'shared/lib/period';
import { validateConfig } from './validateConfig';

export const parseRequest = (
  config: CategoryDiagramSettings,
  currentDate = DateTime.now(),
  currentClassifierId?: string,
  currentRootClassifierId?: string
): ValueRequest | undefined => {
  if (!validateConfig(config)) {
    return;
  }

  let byIndicators: ValueRequestIndicator[] = [];

  if ((config.filters?.splitterValues?.length ?? 0) > 0) {
    byIndicators = defined(config.filters?.splitterValues).map((value) => {
      const period = getDatesFromConfig(
        defined(value.period),
        currentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate(),
        undefined,
        config.common.globalOffset && config.common.globalOffset > 0
          ? { hasOffset: true, offsetDays: config.common.globalOffset }
          : undefined
      );

      return {
        key: value.key,
        indicatorId: defined(config.filters).indicatorId,
        period: config.filters?.byPeriod.enabled
          ? undefined
          : {
              start: period.start,
              end: period.end,
            },
        nodeIds: [value.node],
        forPeriodAggregation: value.aggregationType,
        forPeriodCalculation: value.calculationType,
        withChildrenSum: config.classifiers.childrenSumEnabled,
        nodesAggregation: config.classifiers.aggregationType,
      } as ValueRequestIndicator;
    });
  } else {
    return undefined;
  }

  const nodeIds =
    currentClassifierId && currentRootClassifierId
      ? config.filters?.nodes.map((node) => {
          if (node.rootId === currentRootClassifierId) {
            return {
              ...node,
              nodeId: currentClassifierId,
            } as ClassifierNode;
          } else {
            return node;
          }
        })
      : config.filters?.nodes;

  const byPeriodValue = config.filters?.byPeriod.enabled
    ? getDatesFromConfig(
        defined(config.filters.byPeriod.period),
        currentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate(),
        undefined,
        config.common.globalOffset && config.common.globalOffset > 0
          ? { hasOffset: true, offsetDays: config.common.globalOffset }
          : undefined
      )
    : undefined;

  const request: ValueRequest = {
    byIndicators,
    nodeIds,
    byPeriod:
      config.filters?.byPeriod.enabled && byPeriodValue
        ? {
            start: byPeriodValue.start,
            end: byPeriodValue.end,
            step: config.filters.byPeriod.step,
          }
        : undefined,
    forPeriodAggregation: config.filters?.aggregationType,
    forPeriodCalculation: config.filters?.calculationType,
    withChildrenSum: config.classifiers.childrenSumEnabled,
    nodesAggregation: config.classifiers.aggregationType,
    fillClassifiers: true,
    fillIndicators: false,
    groupByRootId: config.filters?.isGroupByRoot ? undefined : config.temporary.currentRootClassifier,
  };
  return request;
};
