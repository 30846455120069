import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useLocalization } from 'entities/localization';
import { observer } from 'mobx-react';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import theme from 'shared/theme';
import PickersLocaleText_ruRU from 'shared/theme/mui-pickers-locale-ruRU';
import { AppRoutes } from './config/routes';
import { useStartup } from './hooks';
import NotificationsProvider from './ui/NotificationsProvider';
import StartupScreen from './ui/StartupScreen';

const App: React.FC = () => {
  const { L } = useLocalization();
  const { state, countdown } = useStartup();

  return (
    <NotificationsProvider>
      {state === 'Connected' && (
        <React.Suspense fallback={<StartupScreen text={L.Pages.Startup.AppLoadingText} />}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ErrorBoundary>
              <LocalizationProvider dateAdapter={AdapterLuxon} localeText={PickersLocaleText_ruRU} adapterLocale="ru">
                <ThemeProvider theme={theme}>
                  <CssBaseline enableColorScheme />
                  <AppRoutes />
                </ThemeProvider>
              </LocalizationProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </React.Suspense>
      )}
      {state === 'Connecting' && <StartupScreen text={L.Pages.Startup.AppLoadingText} />}
      {state === 'Error' && <StartupScreen text={L.Pages.Startup.NoConnection(countdown)} />}
    </NotificationsProvider>
  );
};

export default observer(App);
