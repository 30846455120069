import { Classifier, ClassifierNode } from 'entities/classifier';
import { loadClassifiersById } from 'entities/classifier/api';
import contextStore from 'entities/context/contextStore';
import { CategoryDiagramSettings } from 'entities/panel';
import { makeAutoObservable, runInAction } from 'mobx';
import { Props } from 'react-apexcharts';
import { defined } from 'shared/lib/checks';
import { generateConfig } from './lib/diagram/generateConfig';
import { DateTime } from 'luxon';
import { isStringHistory } from './lib/diagram/isStringHistory';

class DiagramStore {
  public diagrams: Record<
    string,
    {
      config?: Props;
      isLoading: boolean;
      classifier?: Classifier;
      currentDate?: DateTime;
      currentClassifierId?: string;
    }
  > = {};
  constructor() {
    makeAutoObservable(this);
  }

  handleDrillDownBySettings = (filters: CategoryDiagramSettings, isFirstRender = false) => {
    const drillDown = filters.classifiers.drillDown;
    const history = filters.temporary.history;

    if (drillDown.drillDownOrder?.[0] && isFirstRender) {
      if (!history) {
        runInAction(() => {
          filters.temporary.currentRootClassifier = drillDown.drillDownOrder?.[0];
        });
      }

      // сбрасываю уровень проваливания при первом рендоринге если ранее спускались на уровень ниже
      if (drillDown.drillDownOrder.length > 0 && filters.temporary.currentLevelDrillDown && history) {
        runInAction(() => {
          filters.temporary.currentRootClassifier = drillDown.drillDownOrder?.[0];
          filters.temporary.currentLevelDrillDown = 0;
          filters.temporary.history = null;
        });
        return filters.filters?.nodes;
      }
    }

    if (drillDown.drillDownOrder?.length && history?.[0]?.nodeId.length) {
      const nodeIdsCount = history.reduce((acc, next) => acc + next.nodeId.length, 0);
      const rootIdsArray = drillDown.drillDownOrder.slice(1);
      if (rootIdsArray.length - 1 >= nodeIdsCount - 1) {
        const rootId = rootIdsArray[nodeIdsCount - 1];
        runInAction(() => {
          filters.temporary.currentRootClassifier = rootId;
          filters.temporary.currentLevelDrillDown = nodeIdsCount;
        });
      }
    }
  };

  private isReplaceNodesEmpty(nodes: ClassifierNode[], rootId: string) {
    return nodes.every(({ rootId: nodeRootId }) => nodeRootId !== rootId);
  }

  private handleDefaultDrillDown(filters: CategoryDiagramSettings, isFirstRender = false) {
    let replaceNodes: ClassifierNode[] = [];
    const currentRootClassifierId = filters.temporary.currentRootClassifier;
    const history = filters.temporary.history;

    // обнуляем history если в history строки(для старой версии графиков)
    if (history && isStringHistory(history)) {
      runInAction(() => {
        filters.temporary.history = [];
      });
    }

    const currentClassifierNodeId = history?.find(({ rootId }) => rootId === currentRootClassifierId)?.nodeId[
      defined(history.find(({ rootId }) => rootId === currentRootClassifierId)?.nodeId).length - 1
    ];

    if (currentClassifierNodeId && currentRootClassifierId) {
      const existingReplaceNodeIndex = replaceNodes.findIndex(({ rootId }) => rootId === currentRootClassifierId);
      if (existingReplaceNodeIndex > -1) {
        replaceNodes[existingReplaceNodeIndex] = {
          rootId: currentRootClassifierId,
          nodeId: currentClassifierNodeId,
        };
      } else {
        replaceNodes.push({
          rootId: currentRootClassifierId,
          nodeId: currentClassifierNodeId,
        });
      }
    }

    this.handleDrillDownBySettings(filters, isFirstRender);

    if (history && this.isReplaceNodesEmpty(replaceNodes, defined(currentRootClassifierId))) {
      replaceNodes = history.map(({ rootId, nodeId }) => ({
        rootId,
        nodeId: nodeId.slice(-1).toString(),
      }));
    }

    if (history === null) {
      replaceNodes = [];
    }

    return replaceNodes;
  }

  public async loadDiagram(
    blockId: string,
    {
      filters,
      currentDate,
      currentClassifierId,
      isFirstRender = false,
    }: {
      filters: CategoryDiagramSettings;
      currentDate?: DateTime;
      currentClassifierId?: string;
      isFirstRender?: boolean;
    }
  ) {
    if (!(blockId in this.diagrams)) {
      this.diagrams[blockId] = {
        config: undefined,
        isLoading: false,
      };
    }

    const diagram = defined(this.diagrams[blockId]);
    if (!filters.temporary.currentRootClassifier) {
      return;
    }
    try {
      diagram.isLoading = true;
      diagram.classifier = (
        await loadClassifiersById(defined(contextStore.currentContextId), [filters.temporary.currentRootClassifier])
      )[0];
      diagram.config = await generateConfig(
        blockId,
        filters,
        currentDate || diagram.currentDate,
        currentClassifierId || diagram.currentClassifierId,
        diagram.classifier,
        this.handleDefaultDrillDown(filters, isFirstRender)
      );
    } finally {
      diagram.isLoading = false;
    }
  }
}

export default new DiagramStore();
