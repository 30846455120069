import api from 'shared/api';
import { ValueRequest, ValueResponse } from '../model';

/**
 * Обычный единичный запрос получения данных по показателям для карточки или графика с единичным значением или раскладкой.
 * Пока без периодов.
 * @param {MultiValueRequest} payload Словарь запросов дял карточек и графиков.
 * @returns {Promise<MultiValueResponse>} Словарь результирующих данных карточек и графиков и/или сообщений об ошибках обработки.
 */
export async function getValues(contextId: number, payload: ValueRequest): Promise<ValueResponse> {
  return await api.post<ValueResponse>(`/contexts/${contextId}/dashboards-v2/data`, payload);
}
