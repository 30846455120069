import api from 'shared/api';
import { DataResult, ValueRequest, ValueResponse } from '../model';

/**
 * Комбинированный запрос получения данных по показателям для карточек и графиков с единичным значением или раскладкой.
 * Пока без периодов.
 * В один запрос может быть включено много подзапросов
 * @param {Dictionary<MultiValueRequest>} payload Словарь запросов дял карточек и графиков.
 * @returns {Promise<Dictionary<DataResult<MultiValueResponse>>>} Словарь результирующих данных карточек и графиков и/или сообщений об ошибках обработки.
 */
export async function getValuesBulk(
  contextId: number,
  payload: Record<string, ValueRequest>
): Promise<Record<string, DataResult<ValueResponse>>> {
  return await api.post<Record<string, DataResult<ValueResponse>>>(
    `/contexts/${contextId}/dashboards-v2/data-bulk`,
    payload
  );
}
